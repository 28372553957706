import {
	BROADCAST_BATCH_LOADING,
	BROADCAST_BATCH_DATA,
	BROADCAST_BATCH_TOTAL,
	BROADCAST_BATCH_PAGE,
	BROADCAST_BATCH_ROWS_PER_PAGE,
  BROADCAST_BATCH_SORTER,
	BROADCAST_BATCH_QUERY,
  BROADCAST_BATCH_UPLOADING,
  BROADCAST_BATCH_UPLOADING_PROGRESS,
  BROADCAST_REF_TEMPLATES_LOADING,
  BROADCAST_REF_TEMPLATES_DATA
} from "@constants/ActionTypes";

const INIT_STATE = {
	loading: false,
	data: [],
	total: 0,
	page: 1,
	rowsPerPage: 20,
  sorter: null,
	query: '',
  uploading: false,
  uploadingProgress: 0,
  loadingRefTemplates: false,
  refTemplates: []
};

const Store = (state = INIT_STATE, action) =>
{
  switch (action.type)
  {
    case BROADCAST_BATCH_LOADING: {
      return {...state, loading: action.payload ? true : false};
    }

    case BROADCAST_BATCH_DATA: {
      return {...state, data: action.payload ?? []};
    }

    case BROADCAST_BATCH_TOTAL: {
      return {...state, total: action.payload ?? 0};
    }

    case BROADCAST_BATCH_PAGE: {
      return {...state, page: action.payload ?? 1};
    }

    case BROADCAST_BATCH_ROWS_PER_PAGE: {
      return {...state, rowsPerPage: action.payload ?? 20};
    }

    case BROADCAST_BATCH_SORTER: {
      return {...state, sorter: action.payload ?? null};
    }

    case BROADCAST_BATCH_QUERY: {
    	return {...state, query: action.payload ?? ''};
    }

    case BROADCAST_BATCH_UPLOADING: {
      return {...state, uploading: action.payload ? true : false};
    }

    case BROADCAST_BATCH_UPLOADING_PROGRESS: {
      return {...state, uploadingProgress: action.payload ?? 0};
    }

    case BROADCAST_REF_TEMPLATES_LOADING: {
      return {...state, loadingRefTemplates: action.payload ? true : false};
    }

    case BROADCAST_REF_TEMPLATES_DATA: {
      return {...state, refTemplates: action.payload ?? []};
    }

    default:
      return state;
  }
}

export default Store;