import {
  SCHEDULE_DETAIL_LOADING,
  SCHEDULE_DETAIL_BATCH_ID,
  SCHEDULE_DETAIL_SELECTED_ITEMS,
  SCHEDULE_DETAIL_DELETE,
  SCHEDULE_DETAIL_DATA,
  SCHEDULE_DETAIL_TOTAL,
  SCHEDULE_DETAIL_PAGE,
  SCHEDULE_DETAIL_ROWS_PER_PAGE,
  SCHEDULE_DETAIL_SORTER,
  SCHEDULE_DETAIL_QUERY,
  SCHEDULE_DETAIL_STATUS
} from "@constants/ActionTypes";

const INIT_STATE = {
	loading: false,
  isDeleting: false,
  selectedItems: [],
  batchId: null,
	data: [],
	total: 0,
	page: 1,
	rowsPerPage: 20,
  sorter: null,
	query: '',
  status: null
};

const Store = (state = INIT_STATE, action) =>
{
  switch (action.type)
  {
    case SCHEDULE_DETAIL_LOADING: {
      return {...state, loading: action.payload ? true : false};
    }

    case SCHEDULE_DETAIL_DELETE: {
      return {...state, isDeleting: action.payload ? true : false};
    }

    case SCHEDULE_DETAIL_SELECTED_ITEMS: {
      return {...state, selectedItems: action.payload ?? []};
    }

    case SCHEDULE_DETAIL_BATCH_ID: {
      return {...state, batchId: action.payload ?? null};
    }

    case SCHEDULE_DETAIL_DATA: {
      return {...state, data: action.payload ?? []};
    }

    case SCHEDULE_DETAIL_TOTAL: {
      return {...state, total: action.payload ?? 0};
    }

    case SCHEDULE_DETAIL_PAGE: {
      return {...state, page: action.payload ?? 1};
    }

    case SCHEDULE_DETAIL_ROWS_PER_PAGE: {
      return {...state, rowsPerPage: action.payload ?? 20};
    }

    case SCHEDULE_DETAIL_SORTER: {
      return {...state, sorter: action.payload ?? null};
    }

    case SCHEDULE_DETAIL_QUERY: {
    	return {...state, query: action.payload ?? ''};
    }

    case SCHEDULE_DETAIL_STATUS: {
      return {...state, status: action.payload ?? null};
    }

    default:
      return state;
  }
}

export default Store;
