import axios from "axios";
import store from "@store";
import {getConfig} from "@app/Config";
import Mustache from 'mustache';
import {userPurgeAuth} from "@actions/Auth";
import {notification} from "antd";

const BASE_API = getConfig('BASE_API');
// const BASE_API = 'http://localhost:8090/api/';
const collections = {
	AUTH_LOGIN: 'auth/login',
	AUTH_FORGOT_PASSWORD: 'auth/reset-password-email',
	AUTH_RESET_PASSWORD: 'auth/update-password',
	AUTH_VERIFY_TOKEN: 'auth/verify-token',
	AUTH_PROFILE: 'user/get-profile',
	AUTH_VERIFY_EMAIL: 'user/confirm-email?token={{token}}',
	FUNDER_LIST: 'master-funder',
	FUNDER_LIST_ACCOUNT: 'non-blacklist',
	FUNDER_UPLOAD: 'master-funder/upload',
	FUNDER_DELETE: 'master-funder/multiple-delete',
	FUNDER_CONFIRM: 'master-funder/confirm/{{id}}',
	FUNDER_DECLINE: 'master-funder/decline/{{id}}',
	FUNDER_HISTORY: 'master-funder/history/{{id}}',
	FUNDER_INFO: 'master-funder/{{id}}',
	FUNDER_READ: 'master-funder/timestamp/{{id}}',
	FUNDER_ACCOUNT_DETAIL: 'funder/{{id}}',
	FUNDER_ITEM_LIST: 'funder',
	FUNDER_ITEM_SEND_EMAIL: 'funder/send-email',
	FUNDER_ITEM_PREVIEW_PDF: 'funder/preview-pdf/{{id}}',
	FUNDER_ACCOUNT_DELETE: 'funder/{{id}}',
	FUNDER_DOWNLOAD_ALL_BATCH: 'master-funder/bulk-download/{{id}}',
	FUNDER_CUSTOM_TEMPLATE_DETAIL: 'fs-template',
	FUNDER_CUSTOM_TEMPLATE_UPDATE: 'fs-template',
	BLACKLIST_LIST: 'blacklist/broadcast-email',
	BLACKLIST_DETAIL: 'blacklist/broadcast-email/{{id}}',
	BLACKLIST_CREATE: 'blacklist/broadcast-email',
	BLACKLIST_UPDATE: 'blacklist/broadcast-email/edit-data/{{id}}',
	BLACKLIST_DELETE: 'blacklist/broadcast-email/unblacklist/{{id}}',
	BLACKLIST_REF_TEMPLATES: 'blacklist/ref-templates',
  // blaclist funder
  BLACKLIST_FUNDER_LIST: 'blacklist/funder-statement',
  BLACKLIST_FUNDER_DETAIL: 'blacklist/funder-statement/{{id}}',
  BLACKLIST_FUNDER_CREATE: 'blacklist/funder-statement',
  BLACKLIST_FUNDER_UPDATE: 'blacklist/funder-statement/edit-data/{{id}}',
  BLACKLIST_FUNDER_DELETE: 'blacklist/funder-statement/unblacklist/{{id}}',
  BLACKLIST_FUNDER_REF_TEMPLATES: 'blacklist/ref-templates',
	USER_LIST: 'admin/users',
	USER_DETAIL: 'admin/users/{{id}}',
	USER_CREATE: 'admin/users',
	USER_UPDATE: 'admin/users/{{id}}',
	USER_DELETE: 'admin/users/{{id}}',
	PROFILE_UPDATE: 'user/edit-profile',
	PROFILE_CHANGE_PASSWORD: 'user/change-password',
	TEMPLATES_LIST: 'templates',
	TEMPLATES_CREATE: 'templates',
	TEMPLATES_UPDATE: 'templates/{{id}}',
	TEMPLATES_DELETE: 'templates/{{id}}',
	TEMPLATES_DETAIL: 'templates/{{id}}',
	BROADCAST_BATCH_UPLOAD: 'broadcast/upload',
	BROADCAST_BATCH_REF_TEMPLATES: 'templates',
	BROADCAST_BATCH_LIST: 'broadcast',
	BROADCAST_BATCH_DETAIL: 'broadcast/{{id}}',
	BROADCAST_BATCH_DELETE: 'broadcast/{{id}}',
	BROADCAST_DETAIL_LIST: 'broadcast/details',
	BROADCAST_DETAIL_SEND_EMAIL: 'broadcast/send-email',
	BROADCAST_BATCH_ACCEPT: 'broadcast/{{id}}/confirm',
	BROADCAST_BATCH_REJECT: 'broadcast/{{id}}/reject',
	REF_EMAIL_SENDERS: 'email/ref-email-senders',
	CONFIGURATION_SENDER_LIST: 'sender-email-broadcast',
	CONFIGURATION_SENDER_CREATE: 'sender-email-broadcast',
	CONFIGURATION_SENDER_UPDATE: 'sender-email-broadcast/{{id}}',
	CONFIGURATION_SENDER_DELETE: 'sender-email-broadcast/{{id}}',
	CONFIGURATION_SENDER_DETAIL: 'sender-email-broadcast/{{id}}',
  CONFIGURATION_CC_LIST: 'cc-email-broadcast',
  CONFIGURATION_CC_CREATE: 'cc-email-broadcast',
	CONFIGURATION_CC_UPDATE: 'cc-email-broadcast/{{id}}',
	CONFIGURATION_CC_DELETE: 'cc-email-broadcast/{{id}}',
	CONFIGURATION_CC_DETAIL: 'cc-email-broadcast/{{id}}',
//  broadcast schedule
  BROADCAST_SCHEDULE_BATCH_LIST: "scheduler-email/batch",
  BROADCAST_SCHEDULE_UPLOAD: "scheduler-email/batch-upload",
  BROADCAST_SCHEDULE_UPLOAD_UPDATE: "scheduler-email/batch-upload-add/{{id}}",
  BROADCAST_SCHEDULE_DELETE : "scheduler-email/details/delete",
  BROADCAST_SCHEDULE_BATCH_DELETE: "scheduler-email/batch-delete/{{id}}",
//  broadcast schedule detail
  SCHEDULE_lIST: "scheduler-email/details",
  SCHEDULE_DELETE: "scheduler-email/details/delete",
  SCHEDULE_BATCH_DETAIL: "scheduler-email/batch/{{id}}",
  // additional email receipt
  ADDITIONAL_CREATE: 'additional-email-recipent',
  ADDITIONAL_EMAIL_LIST: 'additional-email-recipent',
  ADDITIONAL_EMAIL_UPDATE: 'additional-email-recipent/{{id}}',
  ADDITIONAL_EMAIL_FUNDER_LIST: '/additional-email-recipent/email-list',
  ADDITIONAL_EMAIL_DELETE: 'additional-email-recipent/{{id}}',
  ADDITIONAL_EMAIL_CUSTOM_DELETE: '/additional-email-recipent/delete-list-email'

};

export const getCollection = (key, object) =>
{
	return collections.hasOwnProperty(key) ? BASE_API + Mustache.render(collections[key], object) : null;
}

axios.create({
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axios.interceptors.request.use(function (config) {
	const {auth: {token}} = store.getState();

	let customHeaders = {};

	if (token) customHeaders['Authorization'] = `Bearer ${token}`;
	// customHeaders['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
	customHeaders['X-Requested-With'] = 'XMLHttpRequest';

	config.headers = customHeaders;

  return config;
});

axios.interceptors.response.use((response) => {
	return response;
}, (error) => {
	const {status} = error?.response ?? {};
	switch (parseInt(status))
	{
		case 401: // unauthorized
			store.dispatch(userPurgeAuth());
			notification.error({
				message: 'Akses ditolak',
				description: 'Silahkan login ulang dengan email & kata sandi anda',
			});
		break;
		default:
		break;
	}

	return Promise.reject(error);
});

export default axios;
