import {
  FILES_BATCH_TYPE,
	FILES_BATCH_ID,
	FILES_LOADING,
	FILES_SELECTED_ITEMS,
  FILES_SENDING,
	FILES_QUERY,
	FILES_SORTER,
	FILES_STATUS,
	FILES_DATA,
	FILES_TOTAL,
	FILES_PAGE,
	FILES_ROWS_PER_PAGE,
  DOWNLOAD_FILES_BULK
} from "@constants/ActionTypes";

const INIT_STATE = {
  batchType: null,
	batchId: null,
	loading: false,
	selectedItems: [],
  sending: false,
  query: '',
  sorter: null,
  status: null,
	data: [],
	total: 0,
	page: 1,
	rowsPerPage: 20,
  loadingDownload: false
};

const Store = (state = INIT_STATE, action) =>
{
  switch (action.type)
  {
    case FILES_BATCH_TYPE: {
      return {...state, batchType: action.payload ?? null};
    }

    case FILES_BATCH_ID: {
      return {...state, batchId: action.payload ?? null};
    }

    case FILES_LOADING: {
      return {...state, loading: action.payload ? true : false};
    }

    case FILES_SELECTED_ITEMS: {
      return {...state, selectedItems: action.payload ?? []};
    }

    case FILES_SENDING: {
      return {...state, sending: action.payload ? true : false};
    }

    case FILES_QUERY: {
      return {...state, query: action.payload ?? ''};
    }

    case FILES_SORTER: {
      return {...state, sorter: action.payload ?? null};
    }

    case FILES_STATUS: {
      return {...state, status: action.payload ?? null};
    }

    case FILES_DATA: {
      return {...state, data: action.payload ?? []};
    }

    case FILES_TOTAL: {
      return {...state, total: action.payload ?? 0};
    }

    case FILES_PAGE: {
      return {...state, page: action.payload ?? 1};
    }

    case FILES_ROWS_PER_PAGE: {
      return {...state, rowsPerPage: action.payload ?? 20};
    }

    case DOWNLOAD_FILES_BULK: {
      return {...state, loadingDownload: action.payload ? true : false};
    }

    default:
      return state;
  }
}

export default Store;
