import {
  BROADCAST_DETAIL_LOADING,
  BROADCAST_DETAIL_BATCH_ID,
  BROADCAST_DETAIL_SELECTED_ITEMS,
  BROADCAST_DETAIL_SENDING,
  BROADCAST_DETAIL_DATA,
  BROADCAST_DETAIL_TOTAL,
  BROADCAST_DETAIL_PAGE,
  BROADCAST_DETAIL_ROWS_PER_PAGE,
  BROADCAST_DETAIL_SORTER,
  BROADCAST_DETAIL_QUERY,
  BROADCAST_DETAIL_STATUS
} from "@constants/ActionTypes";

const INIT_STATE = {
	loading: false,
  sending: false,
  selectedItems: [],
  batchId: null,
	data: [],
	total: 0,
	page: 1,
	rowsPerPage: 20,
  sorter: null,
	query: '',
  status: null
};

const Store = (state = INIT_STATE, action) =>
{
  switch (action.type)
  {
    case BROADCAST_DETAIL_LOADING: {
      return {...state, loading: action.payload ? true : false};
    }

    case BROADCAST_DETAIL_SENDING: {
      return {...state, sending: action.payload ? true : false};
    }

    case BROADCAST_DETAIL_SELECTED_ITEMS: {
      return {...state, selectedItems: action.payload ?? []};
    }

    case BROADCAST_DETAIL_BATCH_ID: {
      return {...state, batchId: action.payload ?? null};
    }

    case BROADCAST_DETAIL_DATA: {
      return {...state, data: action.payload ?? []};
    }

    case BROADCAST_DETAIL_TOTAL: {
      return {...state, total: action.payload ?? 0};
    }

    case BROADCAST_DETAIL_PAGE: {
      return {...state, page: action.payload ?? 1};
    }

    case BROADCAST_DETAIL_ROWS_PER_PAGE: {
      return {...state, rowsPerPage: action.payload ?? 20};
    }

    case BROADCAST_DETAIL_SORTER: {
      return {...state, sorter: action.payload ?? null};
    }

    case BROADCAST_DETAIL_QUERY: {
    	return {...state, query: action.payload ?? ''};
    }

    case BROADCAST_DETAIL_STATUS: {
      return {...state, status: action.payload ?? null};
    }

    default:
      return state;
  }
}

export default Store;