import {
  CONFIGURATION_SENDER_LOADING,
  CONFIGURATION_CC_LOADING,
  CONFIGURATION_SENDER_DATA,
  CONFIGURATION_CC_DATA,
  CONFIGURATION_SENDER_TOTAL,
  CONFIGURATION_SENDER_PAGE,
  CONFIGURATION_SENDER_ROWS_PER_PAGE,
  CONFIGURATION_SENDER_QUERY,
  CONFIGURATION_CC_TOTAL,
  CONFIGURATION_CC_PAGE,
  CONFIGURATION_CC_ROWS_PER_PAGE,
  CONFIGURATION_CC_QUERY
} from "@constants/ActionTypes";


const INIT_STATE = {
  loadingSender: false,
  loadingCc: false,
  dataSender:[],
  dataCc:[],
  pageSender: 1,
  pageCc: 1,
  totalSender: 0,
  totalCc: 0,
  rowsPerPageSender: 20,
  rowsPerPageCc: 20,
  querySender: "",
  queryCc: ""

};

const Store = (state = INIT_STATE, action) =>
{
  switch (action.type)
  {

    case CONFIGURATION_SENDER_LOADING: {
      return {...state, loadingSender: action.payload ? true : false};
    }

    case CONFIGURATION_SENDER_DATA: {
      return {...state, dataSender: action.payload ?? []};
    }

    case CONFIGURATION_SENDER_TOTAL: {
      return {...state, totalSender: action.payload ?? 1};
    }

    case CONFIGURATION_SENDER_PAGE: {
      return {...state, pageSender: action.payload ?? 1};
    }

    case CONFIGURATION_SENDER_ROWS_PER_PAGE: {
      return {...state, rowsPerPageSender: action.payload ?? 20};
    }

    case CONFIGURATION_SENDER_QUERY: {
      return {...state, querySender: action.payload ?? 20};
    }

    case CONFIGURATION_CC_LOADING: {
      return {...state, loadingCc: action.payload ? true : false};
    }

    case CONFIGURATION_CC_DATA: {
      return {...state, dataCc: action.payload ?? []};
    }

    case CONFIGURATION_CC_TOTAL: {
      return {...state, totalCc: action.payload ?? 1};
    }

    case CONFIGURATION_CC_PAGE: {
      return {...state, pageCc: action.payload ?? 1};
    }

    case CONFIGURATION_CC_ROWS_PER_PAGE: {
      return {...state, rowsPerPageCc: action.payload ?? 20};
    }

    case CONFIGURATION_CC_QUERY: {
      return {...state, queryCc: action.payload ?? 20};
    }

    default:
      return state;
  }
}

export default Store;
