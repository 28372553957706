import {
	TEMPLATES_LOADING,
	TEMPLATES_DATA,
	TEMPLATES_TOTAL,
	TEMPLATES_PAGE,
	TEMPLATES_ROWS_PER_PAGE,
  TEMPLATES_SORTER,
	TEMPLATES_QUERY,
  TEMPLATES_FILTER_TYPE
} from "@constants/ActionTypes";

const INIT_STATE = {
	loading: false,
	data: [],
	total: 0,
	page: 1,
	rowsPerPage: 20,
  sorter: null,
	query: '',
  filterType: ""
};

const Store = (state = INIT_STATE, action) =>
{
  switch (action.type)
  {
    case TEMPLATES_LOADING: {
      return {...state, loading: action.payload ? true : false};
    }

    case TEMPLATES_DATA: {
      return {...state, data: action.payload ?? []};
    }

    case TEMPLATES_TOTAL: {
      return {...state, total: action.payload ?? 0};
    }

    case TEMPLATES_PAGE: {
      return {...state, page: action.payload ?? 1};
    }

    case TEMPLATES_ROWS_PER_PAGE: {
      return {...state, rowsPerPage: action.payload ?? 20};
    }

    case TEMPLATES_SORTER: {
      return {...state, sorter: action.payload ?? null};
    }

    case TEMPLATES_QUERY: {
    	return {...state, query: action.payload ?? ''};
    }

    case TEMPLATES_FILTER_TYPE: {
      return {...state, filterType: action.payload ?? ''};
    }

    default:
      return state;
  }
}

export default Store;
