import {
  BROADCAST_SCHEDULER_LOADING,
  BROADCAST_SCHEDULER_DATA,
  BROADCAST_SCHEDULER_TOTAL,
  BROADCAST_SCHEDULER_PAGE,
  BROADCAST_SCHEDULER_ROWS_PER_PAGE,
  BROADCAST_SCHEDULER_SORTER,
  BROADCAST_SCHEDULER_QUERY,
  BROADCAST_SCHEDULER_UPLOADING,
  BROADCAST_SCHEDULER_UPLOADING_PROGRESS,
  BROADCAST_SCHEDULER_REF_TEMPLATES_LOADING,
  BROADCAST_SCHEDULER_REF_TEMPLATES_DATA,
  BROADCAST_SCHEDULER_FILTER_TYPE
} from "@constants/ActionTypes";

const INIT_STATE = {
	loading: false,
	data: [],
	total: 0,
	page: 1,
	rowsPerPage: 20,
  sorter: null,
	query: '',
  uploading: false,
  uploadingProgress: 0,
  loadingRefTemplates: false,
  refTemplates: [],
  filterType: ""
};

const Store = (state = INIT_STATE, action) =>
{
  switch (action.type)
  {
    case BROADCAST_SCHEDULER_LOADING: {
      return {...state, loading: action.payload ? true : false};
    }

    case BROADCAST_SCHEDULER_DATA: {
      return {...state, data: action.payload ?? []};
    }

    case BROADCAST_SCHEDULER_TOTAL: {
      return {...state, total: action.payload ?? 0};
    }

    case BROADCAST_SCHEDULER_PAGE: {
      return {...state, page: action.payload ?? 1};
    }

    case BROADCAST_SCHEDULER_ROWS_PER_PAGE: {
      return {...state, rowsPerPage: action.payload ?? 20};
    }

    case BROADCAST_SCHEDULER_SORTER: {
      return {...state, sorter: action.payload ?? null};
    }

    case BROADCAST_SCHEDULER_QUERY: {
    	return {...state, query: action.payload ?? ''};
    }

    case BROADCAST_SCHEDULER_UPLOADING: {
      return {...state, uploading: action.payload ? true : false};
    }

    case BROADCAST_SCHEDULER_UPLOADING_PROGRESS: {
      return {...state, uploadingProgress: action.payload ?? 0};
    }

    case BROADCAST_SCHEDULER_REF_TEMPLATES_LOADING: {
      return {...state, loadingRefTemplates: action.payload ? true : false};
    }

    case BROADCAST_SCHEDULER_REF_TEMPLATES_DATA: {
      return {...state, refTemplates: action.payload ?? []};
    }

    case BROADCAST_SCHEDULER_FILTER_TYPE: {
      return {...state, filterType: action.payload ?? ""};
    }

    default:
      return state;
  }
}

export default Store;
