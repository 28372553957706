import {
  FUNDERS_VIEW_TYPE,
	FUNDERS_LOADING,
  FUNDERS_DELETING,
	FUNDERS_SELECTED_ITEMS,
	FUNDERS_QUERY,
	FUNDERS_SORTER,
	FUNDERS_STATUS,
  FUNDERS_FILTER_ACCOUNT_NAME,
  FUNDERS_FILTER_ACCOUNT_ID,
  FUNDERS_FILTER_ACCOUNT_EMAIL,
	FUNDERS_DATA,
	FUNDERS_TOTAL,
	FUNDERS_PAGE,
	FUNDERS_ROWS_PER_PAGE,
	FUNDERS_UPLOADING,
	FUNDERS_UPLOADING_PROGRESS,
  FUNDERS_INFO_LOADING,
  FUNDERS_INFO_DATA
} from "@constants/ActionTypes";

const INIT_STATE = {
  viewType: 'batch',
	loading: false,
  deleting: false,
	selectedItems: [],
  query: '',
  sorter: null,
  status: null,
  filterFunderName: '',
  filterFunderId: '',
  filterFunderEmail: '',
	data: [],
	total: 0,
	page: 1,
	rowsPerPage: 20,
	uploading: false,
	uploadingProgress: 0,
  infoLoading: false,
  infoData: null
};

const Store = (state = INIT_STATE, action) =>
{
  switch (action.type)
  {
    case FUNDERS_VIEW_TYPE: {
      return {...state, viewType: action.payload ?? 'batch'};
    }

    case FUNDERS_LOADING: {
      return {...state, loading: action.payload ? true : false};
    }

    case FUNDERS_DELETING: {
      return {...state, deleting: action.payload ? true : false};
    }

    case FUNDERS_SELECTED_ITEMS: {
      return {...state, selectedItems: action.payload ?? []};
    }

    case FUNDERS_QUERY: {
      return {...state, query: action.payload ?? ''};
    }

    case FUNDERS_SORTER: {
      return {...state, sorter: action.payload ?? null};
    }

    case FUNDERS_STATUS: {
      return {...state, status: action.payload ?? null};
    }

    case FUNDERS_FILTER_ACCOUNT_NAME: {
      return {...state, filterFunderName: action.payload ?? ''};
    }

    case FUNDERS_FILTER_ACCOUNT_ID: {
      return {...state, filterFunderId: action.payload ?? ''};
    }

    case FUNDERS_FILTER_ACCOUNT_EMAIL: {
      return {...state, filterFunderEmail: action.payload ?? ''};
    }

    case FUNDERS_DATA: {
      return {...state, data: action.payload ?? []};
    }

    case FUNDERS_TOTAL: {
      return {...state, total: action.payload ?? 0};
    }

    case FUNDERS_PAGE: {
      return {...state, page: action.payload ?? 1};
    }

    case FUNDERS_ROWS_PER_PAGE: {
      return {...state, rowsPerPage: action.payload ?? 20};
    }

    case FUNDERS_UPLOADING: {
    	return {...state, uploading: action.payload ?? false}
    }
    
		case FUNDERS_UPLOADING_PROGRESS: {
			return {...state, uploadingProgress: action.payload ?? 0}
		}

    case FUNDERS_INFO_LOADING: {
      return {...state, infoLoading: action.payload ? true : false};
    }

    case FUNDERS_INFO_DATA: {
      return {...state, infoData: action.payload ?? null};
    }

    default:
      return state;
  }
}

export default Store;