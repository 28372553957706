import {
	// AUTH_LOADING,
	AUTH_TOKEN,
  AUTH_IS_VALID,
  AUTH_ROLE,
  AUTH_PROFILE,
  AUTH_ACCESS_FEATURE
} from "@constants/ActionTypes";
import Api, {getCollection} from "@util/Api";
import {notification} from "antd";

export const userPurgeAuth = () =>
{
	return (dispatch) =>
	{
		dispatch({type: AUTH_TOKEN, payload: null});
		dispatch({type: AUTH_ROLE, payload: null});
		dispatch({type: AUTH_IS_VALID, payload: false});
    dispatch({type: AUTH_ACCESS_FEATURE, payload: null});
	}
}

export const userSignIn = (email, password) =>
{
	return (dispatch) =>
	{
		return new Promise(async (resolve, reject) =>
		{
			try {
				const {data: {data: {token, role, accessFeature}}} = await Api.post(getCollection('AUTH_LOGIN'), {email, password});
				await dispatch({type: AUTH_TOKEN, payload: token});
				await dispatch({type: AUTH_ROLE, payload: role});
				await dispatch({type: AUTH_IS_VALID, payload: true});
				await dispatch({type: AUTH_ACCESS_FEATURE, payload: accessFeature});
				dispatch(userGetProfile());
				resolve(token);
			} catch(e) {
				reject(e);
			}
		});
	}
}

export const userSignOut = () =>
{
	return (dispatch) => dispatch(userPurgeAuth());
}

export const userVerifyToken = () =>
{
	return (dispatch, getState) =>
	{
		const {auth: {token}} = getState();
		return new Promise(async (resolve, reject) =>
		{
			if (token)
			{
				try {
					const {data: {data: {token, role,accessFeature}}} = await Api.get(getCollection('AUTH_VERIFY_TOKEN'));
					await dispatch({type: AUTH_TOKEN, payload: token});
					await dispatch({type: AUTH_ROLE, payload: role});
					await dispatch({type: AUTH_IS_VALID, payload: true});
          await dispatch({type: AUTH_ACCESS_FEATURE, payload: accessFeature});
					await dispatch(userGetProfile());
					resolve();
				} catch(e) {
					reject(e);
				}
			} else {
				dispatch(userPurgeAuth());
				resolve();
			}
		});
	}
}

export const userForgotPassword = (email) =>
{
	return (dispatch) =>
	{
		return new Promise(async (resolve, reject) =>
		{
			try {
				const response = await Api.post(getCollection('AUTH_FORGOT_PASSWORD'), {email});
				resolve(response);
			} catch(e) {
				reject(e);
			}
		});
	}
}

export const userChangePassword = (newPassword, token) =>
{
	return (dispatch) =>
	{
		return new Promise(async (resolve, reject) =>
		{
			try {
				const response = await Api.put(getCollection('AUTH_RESET_PASSWORD'), {newPassword, token});
				resolve(response);
			} catch(e) {
				reject(e);
			}
		});
	}
}

export const userGetProfile = () =>
{
	return (dispatch, getState) =>
	{
		return new Promise(async (resolve, reject) =>
		{
			try {
				const {data: {data}} = await Api.get(getCollection('AUTH_PROFILE'));
				dispatch({type: AUTH_PROFILE, payload: data});
				resolve(data);
			} catch(e) {
				reject(e);
			}
		});
	}
}

export const userUpdateProfile = (payload) =>
{
	return (dispatch, getState) =>
	{
		const {email: oldEmail} = getState()?.auth?.profile ?? {};
		const {email: newEmail} = payload ?? {};
		return new Promise(async (resolve, reject) =>
		{
			try {
				await Api.put(getCollection('PROFILE_UPDATE'), payload);
				if (oldEmail === newEmail) {
		      notification.success({
		        message: 'Berhasil memperbarui profil',
		        description: 'Profil anda berhasil diperbarui'
		      });
				} else {
		      notification.success({
		        message: 'Berhasil memperbarui email & profil',
		        description: 'Link konfirmasi email telah dikirimkan. Mohon cek email anda untuk mengkonfirmasi email baru anda'
		      });
				}
				setTimeout(() => {
					dispatch(userGetProfile());
				}, 100);
				resolve();
			} catch(e) {
				reject(e);
			}
		});
	}
}

export const userUpdatePassword = (payload) =>
{
	return (dispatch, getState) =>
	{
		return new Promise(async (resolve, reject) =>
		{
			try {
				await Api.put(getCollection('PROFILE_CHANGE_PASSWORD'), payload);
	      notification.success({
	        message: 'Kata sandi diperbarui',
	        description: 'Silahkan login ulang menggunakan email dan kata sandi terbaru anda',
	      });
				dispatch(userPurgeAuth());
				resolve();
			} catch(e) {
				reject(e);
			}
		});
	}
}
