import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Common from "./Common";
import Auth from "./Auth";
import Users from "./Users";
import Funders from "./Funders";
import FundersCustomTemplate from "./FundersCustomTemplate";
import Blacklist from "./BlacklistFunder";
import BlacklistBroadcast from "./BlacklistFunder";
import Files from "./Files";
import Templates from "./Templates";
import Broadcast from "./Broadcast";
import BroadcastDetail from "./BroadcastDetail";
import Configuration from "./Configuration";
import BroadcastSchedule from "./BroadcastSchedule";
import BroadcastScheduleDetail from "./BroadcastDetailSchedule";
import Additional from "./Additional";

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  common: Common,
  auth: Auth,
  users: Users,
  funders: Funders,
  fundersCustomTemplate: FundersCustomTemplate,
  blacklist: Blacklist,
  blacklistBroadcast: BlacklistBroadcast,
  files: Files,
  templates: Templates,
  broadcast: Broadcast,
  broadcastDetail: BroadcastDetail,
  configuration: Configuration,
  broadcastSchedule: BroadcastSchedule,
  broadcastScheduleDetail: BroadcastScheduleDetail,
  additional: Additional
});

export default createRootReducer;
