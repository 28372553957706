import {
  FUNDERS_CUSTOM_TEMPLATE_DATA,
  FUNDERS_CUSTOM_TEMPLATE_LOADING
} from "@constants/ActionTypes"


const INIT_STATE = {
  data: [],
  loading: false
}



const Store = (state=INIT_STATE, action)=>{
  switch (action.type){
    case FUNDERS_CUSTOM_TEMPLATE_DATA:
      return {
        ...state, data: action.payload ?? []
      }
    case FUNDERS_CUSTOM_TEMPLATE_LOADING:
      return {
        ...state, loading: action.payload ?? false
      }
    default:
      return state
  }
}


export default Store
