import {
  ADDITIONAL_EMAIL_PAGE,
  ADDITIONAL_EMAIL_ROWS_PER_PAGE,
  ADDITIONAL_DATA,
  ADDITIONAL_EMAIL_LIST,
  ADDITIONAL_EMAIL_LOADING,
  ADDITIONAL_EMAIL_TOTAL,
  ADDITIONAL_EMAIL_QUERY_NAME,
} from "@constants/ActionTypes";

const INIT_STATE = {
  loading: false,
	page: 1,
	rowsPerPage: 20,
  total: 0,
  data: [],
  queryId: "",
  queryName: "",
  queryEmail:"",
  limit: 10,
  offset: 0,
  sorter: null,
  funderEmailList: [],
};

const Store = (state = INIT_STATE, action) =>
{
  switch (action.type)
  {

    case ADDITIONAL_EMAIL_PAGE: {
      return {...state, page: action.payload ?? 1};
    }
    case ADDITIONAL_EMAIL_TOTAL: {
      return {...state, total: action.payload ?? 1};
    }

    case ADDITIONAL_EMAIL_ROWS_PER_PAGE: {
      return {...state, rowsPerPage: action.payload ?? 20};
    }

    case ADDITIONAL_DATA: {
      return {...state, data: action.payload ?? []};
    }

    case ADDITIONAL_EMAIL_LIST: {
      return {...state, funderEmailList: action.payload ?? []};
    }

    case ADDITIONAL_EMAIL_LOADING: {
      return {...state, loading: action.payload ?? false};
    }
    
    case ADDITIONAL_EMAIL_QUERY_NAME: {
      return {...state, queryName: action.payload ?? ""};
    }

    default:
      return state;
  }
}

export default Store;
